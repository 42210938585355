import { useCallback, useContext, useEffect, useState } from "react";
import { supabase } from "../../api";
import { PostgrestError } from "@supabase/supabase-js";
import { Tables, TablesInsert } from "../../types/supabase";
import useError from "../useError";
import { SnackbarContext } from "../../contexts/SnackbarContext"

async function getUserSubscriptions(userId: string) {
    return await supabase.from('wordlist_subscription').select().eq('user_id', userId)
}

async function insertUserSubscription(subscription: TablesInsert<'wordlist_subscription'>) {
    return await supabase.from('wordlist_subscription').insert(subscription).select().single()
}

async function deleteUserSubscription(userId: string, wordlistId: string) {
    return await supabase.from('wordlist_subscription').delete().eq('user_id', userId).eq('wordlist_id', wordlistId)
}

export default function useWordlistSubscriptions(userId: string) {
    const [wordlistSubscriptions, setWordlistSubscriptions] = useState<Tables<'wordlist_subscription'>[]>()
    const [error, setError] = useState<PostgrestError>()
    const { snackbarSuccess } = useContext(SnackbarContext)
    const { handleError } = useError()

    useEffect(() => {
        async function doAsync() {
            const { data: subscriptions, error } = await getUserSubscriptions(userId)
            if (error) {
                setError(error)
                return
            }

            setWordlistSubscriptions(subscriptions)
        }

        doAsync()
    }, [userId])

    const subscribe = useCallback(async (wordlistId: string) => {
        const { data: subscription, error } = await insertUserSubscription({ user_id: userId, wordlist_id: wordlistId })
        if (error) {
            handleError(error)
            return
        }

        setWordlistSubscriptions(prev => prev ? [...prev, subscription] : [subscription])

        snackbarSuccess("Subscription added.")
    }, [userId, snackbarSuccess, handleError])

    const unsubscribe = useCallback(async (wordlistId: string) => {
        const { error } = await deleteUserSubscription(userId, wordlistId)
        if (error) {
            handleError(error)
            return
        }

        setWordlistSubscriptions(prev => prev?.filter(p => p.wordlist_id !== wordlistId))

        snackbarSuccess("Subscription removed.")
    }, [userId, snackbarSuccess, handleError])

    return { wordlistSubscriptions, subscribe, unsubscribe, error }
}
