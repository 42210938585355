import { QueryData } from "@supabase/supabase-js"
import { supabase } from "../../api";

export type WordlistSnapshot = QueryData<typeof wordlistSnapshotSelectQuery>[number]
export const wordlistSnapshotQuery = `
        *,
        wordlist_level (
            wordlist_content(count)
        )
    `
const wordlistSnapshotSelectQuery = supabase
    .from('wordlist')
    .select(wordlistSnapshotQuery)
