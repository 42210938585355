import { useEffect, useMemo, useState } from "react";
import { DictionaryEntry, dictionaryEntryQuery } from "../../types/DictionaryEntry";
import { debounce } from "@mui/material";
import { supabase } from "../../api";
import { isNumeric, containsJapanese } from "../../utils/charUtils";
import useError from "../useError";
import { PostgrestError } from "@supabase/supabase-js";

async function getSearchResults(input: string, callback: (results?: readonly DictionaryEntry[]) => void, handlePostgresError: (error: PostgrestError) => void) {
    if (isNumeric(input))
        return

    if (containsJapanese(input)) {
        const { data: queryResults, error: searchError } = await supabase.rpc("query_dictionary_word", { search_query: input }).limit(10)
        if (searchError) {
            handlePostgresError(searchError)
            return
        }

        const resultDictionaryIds = queryResults.map(d => d.dictionary_entry_id)

        const { data: dictionaryEntries, error: dictionaryEntriesError } = await dictionaryEntryQuery().in("id", resultDictionaryIds)
        if (dictionaryEntriesError) {
            handlePostgresError(dictionaryEntriesError)
            return
        }

        callback(dictionaryEntries)
        return
    }

    const { data: queryResults, error: searchError } = await supabase.rpc("query_dictionary_translation", { search_query: input }).limit(10)
    if (searchError) {
        handlePostgresError(searchError)
        return
    }

    const resultDictionaryMeaningIds = queryResults.map(d => d.dictionary_meaning_id)

    const { data: dictionaryEntries, error: dictionaryEntriesError } = await dictionaryEntryQuery().in("dictionary_meaning.id", resultDictionaryMeaningIds)
    if (dictionaryEntriesError) {
        handlePostgresError(dictionaryEntriesError)
        return
    }

    callback(dictionaryEntries)
}

export default function useWordSearch() {
    const [inputValue, setInputValue] = useState<string>("")
    const [isLoading, setIsLoading] = useState(false)
    const [searchOptions, setSearchOptions] = useState<readonly DictionaryEntry[]>([])
    const { handleError } = useError()

    const fetch = useMemo(
        () =>
            debounce(
                (
                    input: string,
                    callback: (results?: readonly DictionaryEntry[]) => void,
                ) => getSearchResults(input, callback, handleError),
                400,
            ),
        [handleError],
    )

    useEffect(() => {
        if (inputValue === "") {
            setSearchOptions([])
            setIsLoading(false)
            return undefined
        }

        setIsLoading(true)
        fetch(inputValue, (results?: readonly DictionaryEntry[]) => {
            setSearchOptions(results ?? [])
            setIsLoading(false)
        }

        )

    }, [inputValue, fetch])

    return { isLoading, searchOptions, inputValue, setInputValue }
}
