import { useEffect, useState } from "react"
import { ButtonData } from "../../types/study/StandaloneChallengeTypes"
import { insertRandomly } from "../../utils/arrayUtils"

export default function useStandaloneChallenge(char: number, falseChars: number[], continueAction: () => void, mistakeAction: () => void) {
    const [buttons, setButtons] = useState<ButtonData[]>([])

    //TODO: two useEffects needed here because of the messed up dependency chain involving continueAction and the mistakes state
    // this can probably be resolved more easily with React version 19
    useEffect(() => {
        const initialButtons = falseChars.map(c => { return { char: c } as ButtonData })
        insertRandomly(initialButtons, { char: char } as ButtonData)
        setButtons(initialButtons)
    }, [char, falseChars])

    useEffect(() => {
        setButtons(prev => prev.map(b => {
            if (b.char === char)
                return { ...b, action: continueAction }
            else
                return { ...b, action: mistakeAction }
        }))
    }, [char, continueAction, mistakeAction])

    return { buttons }
}
