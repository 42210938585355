import { useContext, useEffect, useState } from "react"
import useError from "../useError"
import { PostgrestError } from "@supabase/supabase-js"
import useWordlistSnapshots from "./useWordlistSnapshots"
import useWordlistSubscriptions from "./useWordlistSubscriptions"
import { SessionContext } from "../../contexts/SessionContext"

export default function useBrowseWordlists(search: string | undefined) {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const { wordlistSnapshots, error: wordlistSnapshotsFetchError, getTotalWordCount, viewWordlist } = useWordlistSnapshots(true, false, 30)
    const { session } = useContext(SessionContext)
    const { wordlistSubscriptions, subscribe, unsubscribe, error: wordlistSubscriptionsFetchError } = useWordlistSubscriptions(session.user.id)
    const { handleError } = useError()
    const [error, setError] = useState<PostgrestError>()

    useEffect(() => {
        if (wordlistSnapshotsFetchError) {
            handleError(wordlistSnapshotsFetchError)
            setError(wordlistSnapshotsFetchError)
            setIsLoading(false)
        }
        if (wordlistSubscriptionsFetchError) {
            handleError(wordlistSubscriptionsFetchError)
            setError(wordlistSubscriptionsFetchError)
            setIsLoading(false)
        }
    }, [wordlistSnapshotsFetchError, wordlistSubscriptionsFetchError, handleError])

    useEffect(() => {
        if (wordlistSnapshots && wordlistSubscriptions) {
            setIsLoading(false)
        }
    }, [wordlistSnapshots, wordlistSubscriptions])

    return { wordlistSnapshots, wordlistSubscriptions, isLoading, error, viewWordlist, getTotalWordCount, subscribe, unsubscribe }
}
