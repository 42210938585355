import { Box, Fade } from "@mui/material"
import { useState } from "react"

    // TODO: reset when triggered even mid fade
export default function ErrorBackdrop({ onFinished }: { onFinished: () => void }) {
    const [show, setShow] = useState(true)

    return (
        <Fade in={show} timeout={{ enter: 2, exit: 2000 }} onEntered={() => setShow(false)} onExited={onFinished} >
            <Box
                position="fixed"
                left={0} right={0} top={0} bottom={0}
                bgcolor="action.mistake"
            />
        </Fade >
    )
}
