import { supabase } from '../api'
import { QueryData } from '@supabase/supabase-js'

export type DictionaryEntry = QueryData<ReturnType<typeof dictionaryEntryQuery>>[number]
export const dictionaryEntryQuery = () => supabase
    .from("dictionary_entry")
    .select(`
        id,
        dictionary_word (
            word,
            index
        ),
        dictionary_reading (
            reading,
            index
        ),
        dictionary_meaning!inner (
            dictionary_meaning_translation (
                translation,
                index
            ),
            lang,
            index
        )
`)
