import { Error } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { PostgrestError } from "@supabase/supabase-js";


export default function ErrorDisplay({ error }: { error: Error | PostgrestError | undefined }) {
    const message = error?.message ?? "An unknown error has occurred. Please report this issue."

    return (
        <Stack bgcolor="error.main" color="error.contrastText" padding={2} direction="row" gap={2}>
            <Error />
            <Typography>{message}</Typography>
        </Stack>
    )
}
