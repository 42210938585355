import { Box, Button, Modal, TextField, Typography } from "@mui/material"
import useNewWordlistModal from "../../hooks/wordlist/useNewWordlistModal"

export default function NewWordlistModal({ open, onClose }: { open: boolean, onClose: () => void }) {
    const { setNewWordlistName, handleSubmitNewWordlist } = useNewWordlistModal()

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={style} component="form" display="flex" flexDirection="column" rowGap="20px" onSubmit={handleSubmitNewWordlist}>
                <Typography variant="h6" component="h2">Create a new Wordlist</Typography>
                <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="outlined"
                    required
                    onChange={e => setNewWordlistName(e.target.value)}
                    inputProps={{ maxLength: 128 }}
                    sx={{ backgroundColor: "grey.50" }}
                />
                <Button variant="contained" type="submit">Continue</Button>
            </Box>
        </Modal>
    )
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 4,
}
