import { Box, Button, Container, Typography } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import Subheader from "../components/Subheader";
import { Link } from "react-router-dom";

export default function TesterSignup() {
    return (
        <Fragment>
            <Subheader title="Alpha-Tester Signup" />
            <Container maxWidth="xl">
                <Box display="flex" flexDirection="column" rowGap="20px" margin={4}>
                    <Typography >
                        Thank you very much for deciding to become an alpha tester for Kanji Puzzle!
                    </Typography>
                    <Typography >
                        To get started,&nbsp;
                        <Link to={"https://matrix.org/try-matrix/"}>please download your preferred Matrix client</Link>
                        &nbsp;and then click the button below to join the Kanji Puzzle testing group.
                    </Typography>
                    <Button
                        variant="contained"
                        sx={{ maxWidth: 200 }}
                        onClick={() => window.open("https://matrix.to/#/!rFeUmwitEhystSWwzt:matrix.org?via=matrix.org", "_blank")}
                    >
                        Join on Matrix
                    </Button>
                </Box>
            </Container>
        </Fragment>
    )
}
