export const PROLONGED_SOUND_MARK = 0x30fc
export const HIRAGANA_START = 0x3041
export const HIRAGANA_END = 0x3096
export const KATAKANA_START = 0x30a1
export const KATAKANA_END = 0x30fc
export const KANJI_START = 0x4e00
export const KANJI_END = 0x9faf

export function isLongDash(input: number) { return input === PROLONGED_SOUND_MARK }

export function isHiragana(input: number) { return input >= HIRAGANA_START && input <= HIRAGANA_END }

export function isKatakana(input: number) { return input >= KATAKANA_START && input <= KATAKANA_END }

export function isKana(input: number) { return isHiragana(input) || isKatakana(input) }

export function isKanji(input: number) { return input >= KANJI_START && input <= KANJI_END }

export function isJapanese(input: number) { return isKana(input) || isKanji(input) || isLongDash(input) }

export function containsJapanese(input: string) {
    for (const letter of input) {
        const char = letter.charCodeAt(0)
        if (isJapanese(char))
            return true
    }
    return false
}

export function isNumeric(n: any) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}
