export enum Positions {
    TOP,
    BOTTOM,
    LEFT,
    RIGHT,
    TARE, // top-left
    TAREC, // bottom-right
    NYO, // bottom-left
    NYOC, // top-right
    KAMAE, // top-right
    KAMAEC, // bottom-left
}

export type DraggableData = {
    primitive: number,
    primitivePosition: Positions | null,
}
