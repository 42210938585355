import { Box, Link, Typography } from "@mui/material"
import { WordlistSnapshot as WordlistSnapshotData } from "../../types/wordlist/WordlistSnapshot"
import WordlistOptionsButtonMenu from "./WordlistOptionsButtonMenu"
import { PropsWithChildren } from "react"

type WordlistSnapshotProps = {
    wordlistSnapshot: WordlistSnapshotData
    wordCount: number
    viewWordlist: (wordlistId: string) => void
    onRemove?: () => void
}

export default function WordlistSnapshot(props: PropsWithChildren<WordlistSnapshotProps>) {
    const { wordlistSnapshot, wordCount, viewWordlist, onRemove, children } = props
    const { id } = wordlistSnapshot

    return (
        <Box display="flex" flexDirection="column" padding={2} minWidth={300} sx={styles.boxBorder} bgcolor="background.primary">
            <Box display="flex" columnGap={2}>
                <Link component='button' onClick={() => viewWordlist(id)}>{wordlistSnapshot.name}</Link>
                <Typography >&#40;{wordCount} words&#41;</Typography>
                <Box flex={1} />
                <WordlistOptionsButtonMenu wordlistSnapshot={wordlistSnapshot} onRemove={onRemove} />
            </Box>
            {children}
        </Box>
    )
}

const styles = {
    boxBorder: {
        borderRadius: 1.5,
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: "background.contrastText",
    }
}
