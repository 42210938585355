import { useCallback, useContext } from "react";
import { SnackbarContext } from "../contexts/SnackbarContext";

//TODO: sending alerts
export default function useWarning() {
    const { snackbarWarning } = useContext(SnackbarContext)

    const handleWarning = useCallback((error: Error) => {
        console.warn(error)
        snackbarWarning(error.message)
    }, [snackbarWarning])

    return { handleWarning }
}
