import { supabase } from "../../api"
import { useCallback, useContext } from "react"
import useError from "../useError"
import { SnackbarContext } from "../../contexts/SnackbarContext"
import { Wordlist, WordlistContent } from "../../types/wordlist/Wordlist"
import { Tables } from "../../types/supabase"

type WordlistTable = Tables<'wordlist'>
type WordlistContentTable = Tables<'wordlist_content'>

async function updateWordlistInfo(wordlist: WordlistTable) {
    const { error: updateError } = await supabase.from('wordlist').update(wordlist).eq('id', wordlist.id)
    if (updateError)
        return { error: updateError }

    return {}
}

//TODO: I hate this a lot
function contentToTableContent(wordlistContent: WordlistContent, wordlistLevelId: number) {
    let result = {
        index: wordlistContent.index,
        dictionary_entry_id: wordlistContent.dictionary_entry?.id,
        wordlist_level_id: wordlistLevelId
    } as WordlistContentTable
    if (wordlistContent.id)
        result.id = wordlistContent.id
    return result
}

async function updateWordlistContent(wordlistContent: WordlistContentTable[], contentToDeleteIds: number[]) {
    const { error: deleteError } = await supabase.from('wordlist_content').delete().in('id', contentToDeleteIds)
    if (deleteError)
        return { error: deleteError }

    const updatedItems = wordlistContent.filter(c => c.id)
    // TODO: update when supabase supports bulk updates
    updatedItems.forEach(async c => {
        const { error: updateError } = await supabase.from('wordlist_content').update(c).eq('id', c.id!)
        if (updateError)
            return { error: updateError }
    })

    const newItems = wordlistContent.filter(c => !c.id)
    const { error: insertError } = await supabase.from('wordlist_content').insert(newItems)
    if (insertError)
        return { error: insertError }

    return {}
}

export default function useWordlistSave(
    wordlist: Wordlist | undefined,
    contentToDelete: WordlistContent[],
    resetWordlistEditStates: (() => void)[]
) {
    const { handleError } = useError()
    const { snackbarSuccess } = useContext(SnackbarContext)

    const handleSubmit = useCallback(async () => {
        if (!wordlist)
            return

        const { wordlist_level, ...wordlistSurfaceData } = wordlist
        const { error: wordlistUpdateError } = await updateWordlistInfo(wordlistSurfaceData)
        if (wordlistUpdateError) {
            handleError(wordlistUpdateError)
            return
        }

        //TODO: test saving multiple levels at once
        const allContent = wordlist_level.flatMap(
            level => level.wordlist_content.map(
                c => contentToTableContent(c, level.id)
            )
        )

        const { error: wordlistContentUpdateError } = await updateWordlistContent(allContent, contentToDelete.map(c => c.id))
        if (wordlistContentUpdateError) {
            handleError(wordlistContentUpdateError)
            return
        }

        snackbarSuccess("Wordlist updated.")
        resetWordlistEditStates.forEach(action => { action() })
    }, [wordlist, contentToDelete, snackbarSuccess, resetWordlistEditStates, handleError])

    return { handleSubmit }
}
