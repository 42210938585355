import { supabase } from '../../api'
import { QueryData } from '@supabase/supabase-js'

export type Wordlist = QueryData<typeof wordlistQuery>[number]
export type WordlistLevel = Wordlist['wordlist_level'][number]
export type WordlistContent = WordlistLevel['wordlist_content'][number]

export const fullWordlistSelectQuery = `
    id,
    name,
    date_created,
    is_public,
    user_id,
    wordlist_level (
        id,
        name,
        index,
        wordlist_content (
            id,
            dictionary_entry (
                id,
                dictionary_word (
                    word,
                    index
                ),
                dictionary_reading (
                    reading,
                    index
                ),
                dictionary_meaning (
                    dictionary_meaning_translation (
                        translation,
                        index
                    ),
                    lang,
                    index
                )
            ),
            index
        )
    )
`
const wordlistQuery = supabase.from("wordlist").select(fullWordlistSelectQuery)
