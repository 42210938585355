import { supabase } from '../api'
import { QueryData } from '@supabase/supabase-js'

export type Kanji = QueryData<ReturnType<typeof kanjiQuery>>[number]
export const kanjiQuery = () => supabase
    .from("kanji")
    .select(`
        id,
        strokes,
        grade,
        freq,
        has_extra_strokes,
        jlpt_old,
        jlpt_new,
        kanji_primitive (
            primitive,
            position
        )
`)
