import { Box, Container, IconButton, Typography } from '@mui/material'
import { AddCircle } from '@mui/icons-material'
import UpgradeModal from '../components/UpgradeModal'
import NewWordlistModal from '../components/dashboard/NewWordlistModal'
import useOpenNewWordlistModal from '../hooks/wordlist/useOpenNewWordlistModal'
import Loadable from '../components/Loadable'
import Subheader from '../components/Subheader'
import { Fragment } from 'react/jsx-runtime'
import useWordlistSnapshots from '../hooks/wordlist/useWordlistSnapshots'
import WordlistSnapshot from '../components/wordlist/WordlistSnapshot'
import WordlistStudyButton from '../components/wordlist/WordlistStudyButton'

export default function Dashboard() {
    const { wordlistSnapshots, setWordlistSnapshots, isLoading, error, getTotalWordCount, viewWordlist } = useWordlistSnapshots(false, true)
    const {
        tryOpenNewWordlistModal,
        newWordlistModalIsOpen,
        setNewWordlistModalIsOpen,
        upgradeModalIsOpen,
        setUpgradeModalIsOpen
    } = useOpenNewWordlistModal(wordlistSnapshots)

    return (
        <Fragment>
            <Subheader title="Dashboard" />
            <Container maxWidth="xl">
                <Box display="flex" flexDirection="column" alignItems="center" rowGap="20px" margin={4}>
                    <Loadable isLoading={isLoading} error={error}>
                        {wordlistSnapshots?.map((s, i) => {
                            const wordCount = getTotalWordCount(s)
                            const onRemove = () => setWordlistSnapshots(prev => prev?.filter(p => p.id !== s.id) ?? [])
                            return <WordlistSnapshot key={i} wordlistSnapshot={s} wordCount={wordCount} viewWordlist={viewWordlist} onRemove={onRemove}>
                                <WordlistStudyButton wordlistId={s.id} />
                            </WordlistSnapshot>
                        })}
                    </Loadable>
                </Box>
                <Box display="flex" flexDirection="column" alignItems="center">
                    <IconButton aria-label="logout" onClick={tryOpenNewWordlistModal} sx={{ borderRadius: 2 }}>
                        <AddCircle sx={{ fontSize: 50 }} color="success" />
                        <Typography variant="caption" sx={{ mt: 1, color: 'text.secondary', fontSize: '0.875rem' }} >Add a new wordlist</Typography>
                    </IconButton>
                </Box>
                <NewWordlistModal open={newWordlistModalIsOpen} onClose={() => setNewWordlistModalIsOpen(false)} />
                <UpgradeModal open={upgradeModalIsOpen} onClose={() => setUpgradeModalIsOpen(false)} />
            </Container >
        </Fragment>)
}
