import React from "react"
import { Autocomplete, CircularProgress, TextField } from "@mui/material"
import { DictionaryEntry } from "../../types/DictionaryEntry"
import WordSearchboxSuggestion from "./WordSearchboxSuggestion"
import useWordSearch from "../../hooks/wordlist/useWordSearch"

export default function WordSearchbox({ selectWord }: { selectWord: Function }) {
    const {isLoading, searchOptions, inputValue, setInputValue} = useWordSearch()

    return (
        <Autocomplete
            sx={{ width: 500 }}
            freeSolo
            options={searchOptions}
            value={inputValue}
            noOptionsText="No words found."
            filterOptions={(x) => x}
            getOptionLabel={o => typeof o === 'string' ? o : o.id.toString()}
            onInputChange={(_e: any, newInputValue: string | DictionaryEntry) => {
                // TODO: on selecting a suggestion, the key (dictionary id) is being passed here as a string
                // and triggering the fetch before inputValue gets set back to ""
                const newStringValue = newInputValue as string
                if (newStringValue)
                    setInputValue(newStringValue)
            }}
            onChange={async (_event: any, newValue: string | DictionaryEntry | null) => {
                if (newValue as DictionaryEntry) {
                    await selectWord(newValue)
                }
                setInputValue("")
            }}
            renderInput={params => (
                <TextField
                    {...params}
                    label="Search input"
                    fullWidth sx={{ backgroundColor: "grey.50" }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? < CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            )}
            renderOption={(props, option) => {
                const dictionaryEntry = option as DictionaryEntry
                if (dictionaryEntry) {
                    const { key, ...optionProps } = { ...props, key: dictionaryEntry.id }
                    return (
                        <WordSearchboxSuggestion key={key} dictionaryEntry={dictionaryEntry} props={optionProps} />
                    )
                }
            }
            }
        />)
}
