import { useCallback, useEffect, useState } from "react"
import { DraggableData, Positions } from "../../types/study/PrimitiveChallengeTypes"
import { Primitive } from "../../types/study/ChallengeData"
import { insertRandomly } from "../../utils/arrayUtils"
import { CheckPositionMatch } from "../../helpers/PrimitivePositionHelper"

export default function usePrimitivesChallenge(primitives: Primitive[], falseChars: number[], continueAction: () => void, mistakeAction: () => void) {
    const [shownPrimitives, setShownPrimitives] = useState<number[]>([])
    const [draggables, setDraggables] = useState<DraggableData[]>([])

    useEffect(() => {
        const initialDraggables = falseChars.map(c => { return { primitive: c } as DraggableData })
        primitives.forEach(p => {
            insertRandomly(initialDraggables, { primitive: p.char, primitivePosition: p.position } as DraggableData)
        })
        setDraggables(initialDraggables)
    }, [primitives, falseChars])

    useEffect(() => {
        if (shownPrimitives.length >= primitives.length) {
            setShownPrimitives([])
            continueAction()
        }
    }, [continueAction, shownPrimitives, primitives])

    const dropPrimitive = useCallback((draggableData: DraggableData, droppedPosition: Positions) => {
        if (draggableData.primitivePosition !== null && CheckPositionMatch(draggableData.primitivePosition, droppedPosition)) {
            setShownPrimitives(prev => [...prev, draggableData.primitive])
            setDraggables(prev => prev.filter(d => d.primitive !== draggableData.primitive))
        }
        else {
            mistakeAction()
        }
    }, [mistakeAction])

    return { shownPrimitives, dropPrimitive, draggables }
}
