import { Box, Button, Modal, Typography } from "@mui/material";
import { useCallback } from "react";

type confirmationModalProps = {
    open: boolean,
    onClose: () => void,
    title: string,
    confirmAction: () => void
    text?: string,
}

export default function ConfirmationModal(props: confirmationModalProps) {
    const { open, onClose, title, text, confirmAction } = props

    const confirm = useCallback(() => {
        confirmAction()
        onClose()
    }, [confirmAction, onClose])

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={styles.modalBox} display="flex" flexDirection="column" rowGap="20px">
                <Typography variant="h6" component="h2">{title}</Typography>
                <Typography>{text}</Typography>
                <Box flex={1} display="flex" flexDirection="row" columnGap={2}>
                    <Button variant="outlined" onClick={confirm}>Confirm</Button>
                    <Button variant="contained" onClick={onClose}>Cancel</Button>
                </Box>
            </Box>
        </Modal>
    )
}

const styles = {
    modalBox: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        padding: 4,
    }
}
