import { Box, Container } from "@mui/material";
import { Fragment } from "react/jsx-runtime";
import Subheader from "../components/Subheader";
import useBrowseWordlists from "../hooks/wordlist/useBrowseWordlists";
import Loadable from "../components/Loadable";
import WordlistSnapshot from "../components/wordlist/WordlistSnapshot";
import WordlistSubscribeButton from "../components/wordlist/WordlistSubscribeButton";

export default function BrowseWordlists() {
    const {
        wordlistSnapshots,
        wordlistSubscriptions,
        isLoading,
        error,
        viewWordlist,
        getTotalWordCount,
        subscribe,
        unsubscribe
    } = useBrowseWordlists(undefined)

    return (
        <Fragment>
            <Subheader title="Browse Wordlists" />
            <Container maxWidth="xl">
                <Box display="flex" flexDirection="column" alignItems="center" rowGap="20px" margin={4}>
                    <Loadable isLoading={isLoading} error={error}>
                        {wordlistSnapshots?.map((s, i) => {
                            const isSubscribed = wordlistSubscriptions?.find(sub => sub.wordlist_id === s.id) !== undefined
                            return <WordlistSnapshot key={i} wordlistSnapshot={s} wordCount={getTotalWordCount(s)} viewWordlist={viewWordlist} >
                                <WordlistSubscribeButton wordlistId={s.id} isSubscribed={isSubscribed} subscribe={subscribe} unsubscribe={unsubscribe} />
                            </WordlistSnapshot>
                        })}
                    </Loadable>
                </Box>
            </Container>
        </Fragment>
    )
}
