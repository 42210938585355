import { Button } from "@mui/material";

type WordlistSubscribeButtonProps = {
    wordlistId: string
    isSubscribed: boolean
    subscribe: (wordlistId: string) => void
    unsubscribe: (wordlistId: string) => void
}

export default function WordlistSubscribeButton({ wordlistId, isSubscribed, subscribe, unsubscribe }: WordlistSubscribeButtonProps) {
    return isSubscribed ?
        <Button variant='contained' onClick={() => unsubscribe(wordlistId)}>Unsubscribe</Button>
        :
        <Button variant='contained' onClick={() => subscribe(wordlistId)}>Subscribe</Button>
}
