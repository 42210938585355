export function insertRandomly(array: any[], newItem: any) {
    array.push(newItem)
    if (array.length === 1)
        return

    const random = Math.floor(Math.random() * array.length)
    const swap = array[random]
    array[random] = newItem
    array[array.length - 1] = swap
}

export function reorder(array: any[], originIndex: number, destinationIndex: number) {
    const result = Array.from(array)
    const [removed] = result.splice(originIndex, 1)
    result.splice(destinationIndex, 0, removed)
    return result
}

function getMinOrMaxValue<T extends { [key: string]: any }>(array: T[], key: keyof T, min: boolean): T | undefined {
    if (array.length < 1) return

    return array.reduce((prev, current) => min ?
        current[key] < prev[key] ? current : prev :
        current[key] > prev[key] ? current : prev
    )
}

export function getMinValue<T extends { [key: string]: any }>(array: T[], key: keyof T): T | undefined {
    return getMinOrMaxValue(array, key, true)
}

export function getMaxValue<T extends { [key: string]: any }>(array: T[], key: keyof T): T | undefined {
    return getMinOrMaxValue(array, key, false)
}
