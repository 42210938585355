import { useCallback } from 'react'
import { Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom'
import Header from './components/Header'
import Dashboard from './screens/Dashboard'
import Study from './screens/Study'
import Wordlist from './screens/Wordlist'
import { Box, ThemeProvider } from '@mui/material'
import { lightTheme } from './theme'
import { SessionContext } from './contexts/SessionContext'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorScreen from './screens/ErrorScreen'
import Loadable from './components/Loadable'
import AuthDisplay from './components/AuthDisplay'
import UserAccount from './screens/UserAccount'
import TesterSignup from './screens/TesterSignup'
import useSession from './hooks/useSession'
import BrowseWordlists from './screens/BrowseWordlists'
import { SnackbarProvider } from './components/SnackbarProvider'

export default function App() {
  const { session, isLoading: isLoadingSession } = useSession()

  const Layout = useCallback(() => {
    return (
      <ErrorBoundary fallbackRender={ErrorScreen}>
        <ThemeProvider theme={lightTheme}>
          <SnackbarProvider>
            <Box height="100vh" bgcolor="background.default">
              <Header user={session?.user} />
              <Loadable isLoading={isLoadingSession} >
                {session ?
                  <SessionContext.Provider value={{ session }}>
                    <Outlet />
                  </SessionContext.Provider> :
                  <AuthDisplay />
                }
              </Loadable>
            </Box>
          </SnackbarProvider>
        </ThemeProvider>
      </ErrorBoundary>
    )
  }, [isLoadingSession, session])

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <Dashboard />
        },
        {
          path: "study/:listId",
          element: <Study />
        },
        {
          path: "wordlist/:id",
          element: <Wordlist />
        },
        {
          path: "wordlists",
          element: <BrowseWordlists />
        },
        {
          path: "user-account",
          element: <UserAccount />
        },
        {
          path: "tester-signup",
          element: <TesterSignup />
        }
      ]
    }
  ])

  return <RouterProvider router={router} />
}
