import { Typography } from "@mui/material"
import { DictionaryEntry } from "../../types/DictionaryEntry"

export default function PartialWordPreview({ dictionaryEntry, charactersShown }: { dictionaryEntry: DictionaryEntry, charactersShown: number }) {
    const word = dictionaryEntry.dictionary_word[0]?.word ?? dictionaryEntry.dictionary_reading[0]?.reading ?? "[Word missing]"
    const preview = word.split("").map(
        (value, i) => charactersShown > i ? value : " _ "
    ).join("")
    return (
        <Typography variant="h3" textAlign="center">{preview}</Typography>
    )
}
