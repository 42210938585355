import { Box, Button, Modal, TextField, Typography } from "@mui/material"
import useChangePassword from "../../hooks/useraccount/useChangePassword"

export default function PasswordChangeModal({ open, onClose }: { open: boolean, onClose: () => void }) {
    const { setNewPassword, setNewPasswordMatch, handleSubmitChangePassword } = useChangePassword(onClose)

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={styles.modalBox} component="form" display="flex" flexDirection="column" rowGap="20px" onSubmit={handleSubmitChangePassword}>
                <Typography variant="h6">Enter new password:</Typography>
                <TextField
                    id="outlined-basic"
                    label="New Password"
                    variant="outlined"
                    required
                    type="password"
                    onChange={e => setNewPassword(e.target.value)}
                    inputProps={{ maxLength: 128 }}
                    sx={{ backgroundColor: "grey.50" }}
                />
                <TextField
                    id="outlined-basic"
                    label="Repeat Password"
                    variant="outlined"
                    required
                    type="password"
                    onChange={e => setNewPasswordMatch(e.target.value)}
                    inputProps={{ maxLength: 128 }}
                    sx={{ backgroundColor: "grey.50" }}
                />
                <Button variant="contained" type="submit">Continue</Button>
            </Box>
        </Modal>
    )
}

const styles = {
    modalBox: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        padding: 4,
    }
}
