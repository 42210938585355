import { createContext } from "react"

interface SnackbarContextContent {
    snackbarSuccess: (value: string) => void,
    snackbarInfo: (value: string) => void,
    snackbarWarning: (value: string) => void,
    snackbarError: (value: string) => void,
}

export const SnackbarContext = createContext<SnackbarContextContent>({} as SnackbarContextContent)
