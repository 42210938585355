const defaultDifficulty = 0.3
const newCorrectDuration = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
const newIncorrectDuration = 4 * 60 * 1000 // minutes*seconds*milliseconds
const maxIncorrectDuration = 24 * 60 * 60 * 1000 // hours*minutes*secords*milliseconds
const correctThreshold = 0.6

export type SrsData = {
    difficulty: number,
    dueDuration: number,
    lastReviewed: number
}

function clamp(min: number, max: number, value: number) {
    return Math.min(Math.max(min, value), max)
}

export function srsUpdate(confidence: number, previous: SrsData | null = null): SrsData {
    const isCorrect = confidence > correctThreshold
    if (!previous) {
        return {
            difficulty: defaultDifficulty,
            dueDuration: isCorrect ? newCorrectDuration : newIncorrectDuration,
            lastReviewed: Date.now()
        } as SrsData
    }

    const overdueMultiplier = isCorrect ? Math.min(2, getOverdueFactor(previous.dueDuration, previous.lastReviewed)) : 1
    const additionalDifficulty = ((8 - 9 * confidence) / 17) * overdueMultiplier
    const difficultyWeight = 3 - 1.7 * (previous.difficulty + additionalDifficulty)
    const dueDurationMultiplier = isCorrect ? 1 + (difficultyWeight - 1) * overdueMultiplier : 1 / (1 + 3 * previous.difficulty)

    const newDifficulty = clamp(0, 1, previous.difficulty + additionalDifficulty)
    var newDueDuration = previous.dueDuration * dueDurationMultiplier
    if (!isCorrect) {
        newDueDuration = Math.min(newDueDuration, maxIncorrectDuration)
    }

    return {
        difficulty: newDifficulty,
        dueDuration: Math.round(newDueDuration),
        lastReviewed: Date.now()
    } as SrsData
}

export function getOverdueFactor(dueDuration: number, lastReviewed: number): number {
    return (Date.now() - lastReviewed) / dueDuration
}
