import { AuthChangeEvent, Session } from "@supabase/supabase-js"
import { useEffect, useState } from "react"
import { supabase } from "../api"
import useError from "../hooks/useError"

export default function useSession() {
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [session, setSession] = useState<Session | null>(null)
    const { handleError } = useError()

    useEffect(() => {
        async function doAsync() {
            const { data: { session }, error } = await supabase.auth.getSession()
            if (error) {
                handleError(error)
                return
            }

            setSession(session)

            supabase.auth.onAuthStateChange((_event: AuthChangeEvent, newSession: Session | null) => {
                setSession(prev => {
                    //TODO: Should remove for mobile?
                    if (_event === 'SIGNED_IN' && prev?.user.id !== newSession?.user.id)
                        return newSession
                    return prev
                })
            })

            setIsLoading(false)
        }

        if (isLoading)
            doAsync()
    }, [handleError, isLoading, setIsLoading, setSession])

    return { session, isLoading }
}
