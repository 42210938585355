import { Snackbar, Alert } from "@mui/material"
import { SnackbarContext } from "../contexts/SnackbarContext"
import useSnackbar from "../hooks/useSnackbar"

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
    const snackbar = useSnackbar()

    return (
        <SnackbarContext.Provider value={snackbar}>
            {children}
            <Snackbar open={snackbar.isOpen} autoHideDuration={4000} onClose={snackbar.handleClose} anchorOrigin={snackbar.origin} >
                <Alert severity={snackbar.severity} variant='filled' > {snackbar.text} </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    )
}
