import { useCallback, FormEvent, useState, useContext } from "react"
import { supabase } from "../../api"
import useError from "../useError"
import { SnackbarContext } from "../../contexts/SnackbarContext"

export default function useUpdateEmail(close: () => void) {
    const { handleError } = useError()
    const { snackbarSuccess } = useContext(SnackbarContext)
    const [newEmail, setNewEmail] = useState<string>()

    const handleSubmitChangeEmail = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!newEmail || newEmail.length < 1)
            return

        const { error } = await supabase.auth.updateUser({
            email: newEmail
        })

        if (error) {
            handleError(error)
            return
        }

        snackbarSuccess("Confirmation email sent!")
        close()

    }, [close, handleError, newEmail, snackbarSuccess])

    return { setNewEmail, handleSubmitChangeEmail }
}
