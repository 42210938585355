import { AlertColor, SnackbarOrigin } from "@mui/material"
import { useCallback, useState } from "react"

const defaultOrigin = { vertical: 'bottom', horizontal: 'left' } as SnackbarOrigin
const defaultSeverity = 'success' as AlertColor

export default function useSnackbar() {
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [text, setText] = useState<string>("")
    const [origin, setOrigin] = useState<SnackbarOrigin>(defaultOrigin)
    const [severity, setSeverity] = useState<AlertColor>(defaultSeverity)

    const openSnackbar = useCallback((text: string, origin: SnackbarOrigin = defaultOrigin, severity: AlertColor = defaultSeverity) => {
        setText(text)
        setOrigin(origin)
        setSeverity(severity)
        setIsOpen(true)
    }, [])

    const snackbarSuccess = useCallback((text: string) => {
        openSnackbar(text, { vertical: 'bottom', horizontal: 'left' }, 'success')
    }, [openSnackbar])

    const snackbarInfo = useCallback((text: string) => {
        openSnackbar(text, { vertical: 'bottom', horizontal: 'left' }, 'info')
    }, [openSnackbar])

    const snackbarWarning = useCallback((text: string) => {
        openSnackbar(text, { vertical: 'bottom', horizontal: 'left' }, 'warning')
    }, [openSnackbar])

    const snackbarError = useCallback((text: string) => {
        openSnackbar(text, { vertical: 'bottom', horizontal: 'left' }, 'error')
    }, [openSnackbar])

    return { isOpen, text, origin, severity, handleClose: () => setIsOpen(false), snackbarSuccess, snackbarInfo, snackbarWarning, snackbarError }
}
