import { useCallback, FormEvent, useState, useContext } from "react"
import { useNavigate } from "react-router-dom"
import { supabase } from "../../api"
import { SessionContext } from "../../contexts/SessionContext"
import useError from "../useError"

export default function useNewWordlistModal() {
    const navigate = useNavigate()
    const { session } = useContext(SessionContext)
    const { handleError } = useError()
    const [newWordlistName, setNewWordlistName] = useState<string>()

    const handleSubmitNewWordlist = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!newWordlistName || newWordlistName.length < 1)
            return

        const { data: wordlist, error } = await supabase.from("wordlist").insert({ name: newWordlistName, user_id: session.user.id }).select().single()
        if (error) {
            handleError(error)
            return
        }

        navigate("/wordlist/" + wordlist.id)
    }, [handleError, navigate, newWordlistName, session.user.id])

    return { setNewWordlistName, handleSubmitNewWordlist }
}
