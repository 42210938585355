import { DndProvider, usePreview } from 'react-dnd-multi-backend'
import { HTML5toTouch } from 'rdndmb-html5-to-touch'
import DropAreaContainer from './DropAreaContainer'
import { DictionaryEntry } from '../../types/DictionaryEntry'
import { PrimitivesChallengeData } from '../../types/study/ChallengeData'
import { Box, Typography } from '@mui/material'
import Draggable from './Draggable'
import { DraggableData } from '../../types/study/PrimitiveChallengeTypes'
import usePrimitivesChallenge from '../../hooks/study/usePrimitivesChallenge'
import { PrimitivesChallengeContext } from '../../contexts/PrimitivesChallengeContext'
import { useContext } from 'react'
import { StudyContext } from '../../contexts/StudyContext'

function renderDraggable(data: DraggableData) {
    return (
        <Draggable key={data.primitive} primitive={data.primitive} primitivePosition={data.primitivePosition} />
    )
}

export default function PrimitivesChallenge(
    { dictionaryEntry, challenge, children }:
        { dictionaryEntry: DictionaryEntry, challenge: PrimitivesChallengeData, children: JSX.Element }
) {
    const studyContext = useContext(StudyContext)
    const { shownPrimitives, dropPrimitive, draggables } = usePrimitivesChallenge(
        challenge.primitives, challenge.falsePrimitives, studyContext.continueAction, studyContext.mistakeAction
    )

    return (
        <PrimitivesChallengeContext.Provider value={{ handleDrop: dropPrimitive }}>
            <DndProvider options={HTML5toTouch}>
                <Box display="flex" flexDirection="column" rowGap="40px">
                    {children}
                    <Typography variant="h3" textAlign="center">
                        {dictionaryEntry?.dictionary_meaning[0]?.dictionary_meaning_translation[0]?.translation ?? "[Translation missing]"}
                    </Typography>
                    <DropAreaContainer kanjiSvgString={challenge.kanjiSvg} shownPrimitives={shownPrimitives} />
                    <Box display="flex" flexDirection="row" flex={1} flexWrap="wrap" justifyContent="center" columnGap="20px" zIndex="fab">
                        {draggables.map(d => renderDraggable(d))}
                    </Box>
                </Box>
            </DndProvider>
        </PrimitivesChallengeContext.Provider>
    )
}
