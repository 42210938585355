import { useCallback, useContext, useEffect, useState } from "react"
import useError from "../useError"
import { PostgrestError } from "@supabase/supabase-js"
import { SessionContext } from "../../contexts/SessionContext"
import { fullWordlistSelectQuery, Wordlist } from "../../types/wordlist/Wordlist"
import { supabase } from "../../api"

export default function useWordlist(wordlistId: string | undefined) {
    const [wordlist, setWordlist] = useState<Wordlist>()
    const [error, setError] = useState<Error | PostgrestError>()
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isOwner, setIsOwner] = useState<boolean>(false)
    const { handleError } = useError()
    const { session } = useContext(SessionContext)

    const fetchWordlist = useCallback(async (wordlistId?: string) => {
        if (!wordlistId) {
            const undefinedError = new Error("Wordlist not found! Please verify the URL.")
            handleError(undefinedError)
            setError(undefinedError)
            setIsLoading(false)
            return
        }

        const { data: wordlist, error } = await supabase
            .from('wordlist')
            .select(fullWordlistSelectQuery)
            .eq('id', wordlistId)
            .order('index', { ascending: true, referencedTable: 'wordlist_level.wordlist_content' })
            .single()
        if (error) {
            handleError(error)
            setError(error)
            setIsLoading(false)
            return
        }

        setWordlist(wordlist)
        setIsOwner(wordlist.user_id === session.user.id)
    }, [handleError, session.user.id])

    useEffect(() => {
        async function doAsync() {
            await fetchWordlist(wordlistId)
            setIsLoading(false)
        }

        doAsync()
    }, [handleError, fetchWordlist, wordlistId])

    return { wordlist, setWordlist, isOwner, isLoading, error }
}
