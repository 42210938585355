import { Box, Container, Typography } from "@mui/material";

export default function ErrorScreen({ error }: { error: Error }) {
    return (
        <Box height="100vh" bgcolor="background.default">
            <Container maxWidth="xl">
                <Box display="flex" flexDirection="column" alignItems="center" rowGap={5} marginTop="100px">
                    <Typography variant="h4">
                        An error has occurred.
                    </Typography>
                    <Typography variant="h5">
                        Please report the error to <a href="mailto:andrew@kanjipuzzle.com">my email</a>.
                    </Typography>
                    <Typography>
                        Error: {error.message}
                    </Typography>
                    {error.stack &&
                        <Typography>
                            Call stack: {error.stack}
                        </Typography>
                    }
                    <Typography variant="h4">
                        <a href="/">Click here to return</a>
                    </Typography>
                </Box>
            </Container>
        </Box>
    )
}
