import { useCallback, useContext } from "react";
import { SnackbarContext } from "../contexts/SnackbarContext";
import { PostgrestError } from "@supabase/supabase-js";

//TODO: sending alerts
export default function useError() {
    const { snackbarError } = useContext(SnackbarContext)

    const handleError = useCallback((error: Error | PostgrestError) => {
        console.error(error)
        snackbarError(error.message)
    }, [snackbarError])

    return { handleError }
}
