import Kanji from "./Kanji"
import DropArea from "./DropArea"
import { Box } from "@mui/material"
import { Positions } from "../../types/study/PrimitiveChallengeTypes"

export default function DropAreaContainer({ kanjiSvgString, shownPrimitives }: { kanjiSvgString: string | undefined, shownPrimitives: number[] }) {
    return (
        <Box height="300px" width="300px" position="relative" bgcolor="common.white" alignSelf="center" border="1px solid" borderColor="grey.500">
            <Kanji svgString={kanjiSvgString} shownPrimitives={shownPrimitives} showStrokeNumbers={false} />
            <Box>
                <DropArea thisPosition={Positions.LEFT} clipPath="polygon(0% 0%, 50% 50%, 0% 100%)" />
                <DropArea thisPosition={Positions.TOP} clipPath="polygon(0% 0%, 100% 0%, 50% 50%)" />
                <DropArea thisPosition={Positions.BOTTOM} clipPath="polygon(0% 100%, 50% 50%, 100% 100%)" />
                <DropArea thisPosition={Positions.RIGHT} clipPath="polygon(100% 0%, 50% 50%, 100% 100%)" />
            </Box>
        </Box>
    )
}
