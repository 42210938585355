import { Box } from "@mui/material";
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { supabase } from '../api'

export default function AuthDisplay() {
    return (
        <Box sx={styles.modalBox} display="flex" flexDirection="column" rowGap="20px">
            <Auth supabaseClient={supabase} appearance={{ theme: ThemeSupa }} providers={["google", "discord"]} />
        </Box>
    )
}

const styles = {
    modalBox: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        padding: 4,
    }
}
