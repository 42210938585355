import { useState, useContext, useCallback, useEffect } from "react"
import { DictionaryEntry } from "../../types/DictionaryEntry"
import { SessionContext } from "../../contexts/SessionContext"
import { getRolePermissionLimit } from "../../helpers/SessionHelper"
import useError from "../useError"
import { DropResult } from "react-beautiful-dnd"
import { WordlistContent, WordlistLevel } from "../../types/wordlist/Wordlist"
import { reorder } from "../../utils/arrayUtils"

function reindexWordlistContent(wordlistContent: WordlistContent[]) {
    return wordlistContent.map((c, i) => ({ ...c, index: i }))
}

export default function useWordlistLevelEditContent(
    wordlistLevel: WordlistLevel | undefined,
    updateWordlistLevel: (wordlistLevel: WordlistLevel) => void
) {
    const { session } = useContext(SessionContext)
    const { handleError } = useError()
    const [contentToDelete, setContentToDelete] = useState<WordlistContent[]>([])
    const [contentCreationLimit, setContentCreationLimit] = useState<number | null>()
    const [upgradeModalIsOpen, setUpgradeModalIsOpen] = useState(false)
    const [isDirty, setIsDirty] = useState(false)

    useEffect(() => {
        async function doAsync() {
            if (handleError && session) {
                const { limit, error } = await getRolePermissionLimit(session, "wordlist_content.creation")
                if (error) {
                    handleError(error)
                    return
                }

                setContentCreationLimit(limit)
            }
        }

        doAsync()
    }, [handleError, session])

    const resetState = () => {
        setContentToDelete([])
        setUpgradeModalIsOpen(false)
        setIsDirty(false)
    }

    const deleteItem = useCallback((contentToDelete: WordlistContent) => {
        if (!wordlistLevel)
            return

        if (contentToDelete.id)
            setContentToDelete(content => [...content, contentToDelete])

        updateWordlistLevel({
            ...wordlistLevel,
            wordlist_content: reindexWordlistContent(wordlistLevel.wordlist_content.filter(c => c !== contentToDelete))
        })
        setIsDirty(true)
    }, [updateWordlistLevel, wordlistLevel])

    const addWordToList = useCallback(async (newWord: DictionaryEntry | null) => {
        if (!newWord || !wordlistLevel)
            return

        const existingContent = wordlistLevel.wordlist_content
        if (contentCreationLimit && existingContent.length >= contentCreationLimit) {
            setUpgradeModalIsOpen(true)
            return
        }

        const highestIndex = existingContent.length < 1 ? -1 : Math.max(...existingContent.map(c => c.index))
        const newContent = {
            dictionary_entry: newWord,
            index: highestIndex + 1
        } as WordlistContent

        updateWordlistLevel({
            ...wordlistLevel,
            wordlist_content: [...existingContent, newContent]
        })

        setIsDirty(true)
    }, [contentCreationLimit, updateWordlistLevel, wordlistLevel])

    const reorderWordlistContent = useCallback((originIndex: number, destinationIndex: number) => {
        if (!wordlistLevel)
            return

        const existingContent = wordlistLevel.wordlist_content
        const reindexedContent = reindexWordlistContent(reorder(existingContent, originIndex, destinationIndex))
        updateWordlistLevel({
            ...wordlistLevel,
            wordlist_content: reindexedContent
        })
        setIsDirty(true)
    }, [updateWordlistLevel, wordlistLevel])

    const onDragEnd = useCallback(({ destination, source }: DropResult) => {
        // dropped outside the list
        if (!destination) return

        reorderWordlistContent(source.index, destination.index)
        setIsDirty(true)
    }, [reorderWordlistContent])

    return { deleteItem, contentToDelete, addWordToList, onDragEnd, isDirty, resetState, upgradeModalIsOpen, setUpgradeModalIsOpen }
}
