import { IconButton, Menu, MenuItem } from "@mui/material"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Fragment, useState } from "react"

export default function WordlistItemOptionsButtonMenu({ deleteItem }: { deleteItem: () => void }) {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleClickRemove = () => {
      deleteItem()
      handleClose()
    }

    return (
        <Fragment>
            <IconButton aria-label="options" onClick={handleClick}><MoreHorizIcon /></IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleClickRemove}>Remove</MenuItem>
            </Menu>
        </Fragment>
    )
}
