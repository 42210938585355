import { useNavigate, useParams } from 'react-router-dom'
import WordSearchbox from '../components/wordlistview/WordSearchbox'
import { Box, Button, Container, FormControlLabel, IconButton, Switch } from '@mui/material'
import useWordlistLevelEditContent from '../hooks/wordlist/useWordlistLevelEditContent'
import UpgradeModal from '../components/UpgradeModal'
import Loadable from '../components/Loadable'
import useWordlist from '../hooks/wordlist/useWordlist'
import useWordlistListEntries from '../hooks/wordlist/useWordlistListEntries'
import Subheader from '../components/Subheader'
import { Fragment } from 'react/jsx-runtime'
import WordlistEntriesList from '../components/wordlistview/WordlistEntriesList'
import ConfirmationModal from '../components/ConfirmationModal'
import useWordlistEdit from '../hooks/wordlist/useWordlistEdit'
import useWordlistSave from '../hooks/wordlist/useWordlistSave'
import { Edit } from '@mui/icons-material'
import ReactRouterPrompt from 'react-router-prompt'
import SaveChangesModal from '../components/SaveChangesModal'
import { FormEvent, useCallback } from 'react'
import useWordlistLevel from '../hooks/wordlist/useWordlistLevel'

export default function Wordlist() {
    const params = useParams()
    const navigate = useNavigate()

    const { wordlist, setWordlist, isOwner } = useWordlist(params.id)
    const {
        currentWordlistLevelId,
        editMode,
        toggleEditMode,
        togglePublic,
        confirmSetPublic,
        publicConfirmationModalIsOpen,
        setPublicConfirmationModalIsOpen,
        isDirty: wordlistIsDirty,
        resetState: resetWordlistEditState
    } = useWordlistEdit(wordlist, setWordlist, isOwner)
    const { getCurrentWordlistLevel, updateWordlistLevel } = useWordlistLevel(currentWordlistLevelId, setWordlist)
    const {
        deleteItem,
        contentToDelete,
        addWordToList,
        onDragEnd,
        isDirty: contentIsDirty,
        resetState: resetWordlistLevelEditState,
        upgradeModalIsOpen,
        setUpgradeModalIsOpen
    } = useWordlistLevelEditContent(getCurrentWordlistLevel(wordlist), updateWordlistLevel)
    const { wordlistListEntries, isLoading: isTableLoading } = useWordlistListEntries(getCurrentWordlistLevel(wordlist)?.wordlist_content, deleteItem)
    const { handleSubmit } = useWordlistSave(wordlist, contentToDelete, [resetWordlistEditState, resetWordlistLevelEditState])

    const modals = (
        <Fragment>
            <UpgradeModal open={upgradeModalIsOpen} onClose={() => setUpgradeModalIsOpen(false)} />
            <ConfirmationModal open={publicConfirmationModalIsOpen} onClose={() => setPublicConfirmationModalIsOpen(false)}
                title="Are you sure you want to make this wordlist public?"
                text="This will make the wordlist available for other users to study, but still only you can edit it."
                confirmAction={confirmSetPublic}
            />
            <ReactRouterPrompt when={wordlistIsDirty || contentIsDirty}>
                {({ isActive, onConfirm, onCancel }) => (
                    <SaveChangesModal open={isActive}
                        title="Unsaved Changes"
                        text="Are you sure you want to continue?"
                        onSave={handleSubmit}
                        onContinue={onConfirm}
                        onClose={onCancel}
                    />
                )}
            </ReactRouterPrompt>
        </Fragment>
    )

    const formSubmit = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        await handleSubmit()
    }, [handleSubmit])

    return (
        <Fragment>
            <Subheader title={`${wordlist?.name ?? ""}`} backAction={() => navigate(-1)}>
                {isOwner &&
                    <IconButton onClick={toggleEditMode}
                        sx={{ borderRadius: 2, display: 'inline-flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "primary.main" }}
                    >
                        <Edit sx={{ marginX: 5, color: "primary.contrastText" }} />
                    </IconButton>
                }
            </Subheader>
            <Container maxWidth="xl">
                <Box margin="20px 0px">
                    {wordlist && editMode &&
                        <FormControlLabel label={wordlist.is_public ? "Public" : "Private"} control={
                            <Switch checked={wordlist.is_public} onChange={togglePublic} />
                        } />
                    }
                    <Box component="form" onSubmit={formSubmit} display="flex" flexDirection="column" rowGap="20px">
                        <Loadable isLoading={isTableLoading}>
                            {
                                wordlistListEntries &&
                                <WordlistEntriesList wordlistListEntries={wordlistListEntries} editMode={editMode} onDragEnd={onDragEnd} />
                            }
                        </Loadable>
                        {editMode &&
                            <Fragment>
                                <WordSearchbox selectWord={addWordToList} />
                                <Box>
                                    <Button variant="contained" type="submit" disabled={!wordlistIsDirty && !contentIsDirty}>Save</Button>
                                </Box>
                            </Fragment>
                        }
                    </Box>
                </Box>
                {editMode && modals}
            </Container>
        </Fragment>
    )
}
