import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material"
import { Fragment, useCallback, useContext, useState } from "react"
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import { supabase } from "../api"
import { Logout } from "@mui/icons-material"
import { useNavigate } from "react-router-dom"
import ConfirmationModal from "./ConfirmationModal"
import useError from "../hooks/useError"
import { SnackbarContext } from "../contexts/SnackbarContext"

export default function UserAccountOptionsButtonMenu() {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)
    const [isLogoutConfirmationOpen, setLogoutConfirmationOpen] = useState<boolean>(false)
    const { handleError } = useError()
    const { snackbarSuccess } = useContext(SnackbarContext)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleClickAccountOptions = () => {
        navigate("/user-account")
        handleClose()
    }

    const handleClickLogout = () => {
        setLogoutConfirmationOpen(true)
        handleClose()
    }

    const signOut = useCallback(async () => {
        const { error } = await supabase.auth.signOut()

        if (error) {
            handleError(error)
            return
        }

        snackbarSuccess("Signed out")
    }, [handleError, snackbarSuccess])

    return (
        <Fragment>
            <IconButton aria-label="account options" onClick={handleClick}><AccountBoxIcon /></IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={handleClickAccountOptions}>
                    <ListItemText>
                        Account options
                    </ListItemText>
                </MenuItem>
                <MenuItem onClick={handleClickLogout}>
                    <ListItemText>
                        Log out
                    </ListItemText>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                </MenuItem>
            </Menu>
            <ConfirmationModal
                open={isLogoutConfirmationOpen}
                onClose={() => setLogoutConfirmationOpen(false)}
                title="Confirm log out"
                confirmAction={signOut}
            />
        </Fragment>
    )
}
