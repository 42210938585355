import { useCallback, useEffect, useState } from "react"
import { Wordlist } from "../../types/wordlist/Wordlist"
import { getMinValue } from "../../utils/arrayUtils"

export default function useWordlistEdit(
    wordlist: Wordlist | undefined,
    setWordlist: (value: (value: Wordlist | undefined) => Wordlist | undefined) => void,
    isOwner: boolean
) {
    const [editMode, setEditMode] = useState(false)
    const [publicConfirmationModalIsOpen, setPublicConfirmationModalIsOpen] = useState(false)
    const [isDirty, setIsDirty] = useState(false)
    const [currentWordlistLevelId, setCurrentWordlistLevelId] = useState<number>()

    useEffect(() => {
        if (!wordlist)
            return

        // default shown level, only update if it's undefined
        setCurrentWordlistLevelId(prev => prev ? prev : getMinValue(wordlist.wordlist_level, 'index')?.id)
    }, [wordlist])

    const resetState = useCallback(() => {
        setEditMode(false)
        setPublicConfirmationModalIsOpen(false)
        setIsDirty(false)
        setCurrentWordlistLevelId(wordlist && getMinValue(wordlist.wordlist_level, 'index')?.id)
    }, [wordlist])

    const toggleEditMode = () => {
        setEditMode(prev => !prev && isOwner)
    }

    const setWordlistIsPublic = (isPublic: boolean) => {
        setWordlist(prev => prev ? { ...prev, is_public: isPublic } : prev)
        setIsDirty(true)
    }

    const togglePublic = () => {
        if (!wordlist)
            return
        if (wordlist.is_public) {
            setWordlistIsPublic(false)
        }
        else {
            setPublicConfirmationModalIsOpen(true)
        }
    }

    const confirmSetPublic = () => {
        setWordlistIsPublic(true)
        setPublicConfirmationModalIsOpen(false)
    }

    return {
        currentWordlistLevelId,
        editMode,
        toggleEditMode,
        togglePublic,
        confirmSetPublic,
        publicConfirmationModalIsOpen,
        setPublicConfirmationModalIsOpen,
        isDirty,
        resetState
    }
}
