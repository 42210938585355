import { IconButton, Menu, MenuItem } from "@mui/material"
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { Fragment } from "react"
import ConfirmationModal from "../ConfirmationModal"
import useWordlistOptionsButtonMenu from "../../hooks/wordlist/useWordlistOptionsButtonMenu"
import { WordlistSnapshot } from "../../types/wordlist/WordlistSnapshot"

export default function WordlistOptionsButtonMenu({ wordlistSnapshot, onRemove }: { wordlistSnapshot: WordlistSnapshot, onRemove?: () => void }) {
    const {
        isOpen,
        anchorEl,
        confirmationModalIsOpen,
        setConfirmationModalOpen,
        handleClick, handleClose,
        handleClickEdit,
        handleClickDelete,
        handleDelete
    } = useWordlistOptionsButtonMenu(wordlistSnapshot, onRemove)

    return (
        <Fragment>
            <IconButton aria-label="options" onClick={handleClick}><MoreHorizIcon /></IconButton>
            <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
                <MenuItem onClick={handleClickEdit}>View/Edit Wordlist</MenuItem>
                {
                    onRemove &&
                    <MenuItem onClick={handleClickDelete} sx={{ backgroundColor: "error.main" }}>Delete Wordlist</MenuItem>
                }
            </Menu>
            <ConfirmationModal
                open={confirmationModalIsOpen}
                onClose={() => setConfirmationModalOpen(false)}
                title={`Delete ${wordlistSnapshot.name}?`}
                text="Warning! This will delete all words in the list. This action cannot be undone."
                confirmAction={handleDelete}
            />
        </Fragment>
    )
}
