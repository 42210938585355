import { Box } from '@mui/material'
import XMLParser, { XMLElement } from 'react-xml-parser'

const emptySvg = "<svg />"

function setWidthHeight(svg: XMLElement) {
    svg.attributes["width"] = "100%"
    svg.attributes["height"] = "100%"
}

function getModifiedSvg(svgString: string | undefined, shownPrimitives: number[], showStrokeNumbers: boolean): string {
    if (!svgString || svgString.length === 0) return emptySvg

    var svg = new XMLParser().parseFromString(svgString)
    if (!svg) return emptySvg
    if (svg.children.length < 1) return emptySvg

    setWidthHeight(svg)

    var mainIndex: number | undefined
    var strokeNumbersIndex: number | undefined
    for (var i = 0; i < svg.children.length; i++) {
        if (svg.children[i]['attributes']['id'].includes("StrokePaths")) {
            mainIndex = i
        }
        else if (svg.children[i]['attributes']['id'].includes("StrokeNumbers")) {
            strokeNumbersIndex = i
        }
    }

    if (mainIndex === undefined || strokeNumbersIndex === undefined)
        return emptySvg

    if (!showStrokeNumbers) {
        delete svg.children[strokeNumbersIndex]
    }

    const fullChar = svg.children[mainIndex].children[0]['attributes']['ns1:element']?.codePointAt(0)
    if (fullChar && shownPrimitives.includes(fullChar))
        return new XMLParser().toString(svg)

    for (var j = 0; j < svg.children[mainIndex].children[0].children.length; j++) {
        const primitive = svg.children[mainIndex].children[0].children[j]['attributes']['ns1:element']?.codePointAt(0)
        if (!primitive || !shownPrimitives.includes(primitive))
            delete svg.children[mainIndex].children[0].children[j]
    }

    return new XMLParser().toString(svg)
}

export default function Kanji({ svgString, shownPrimitives, showStrokeNumbers }: { svgString: string | undefined, shownPrimitives: number[], showStrokeNumbers: boolean }) {
    return (<Box position="absolute" dangerouslySetInnerHTML={{ __html: getModifiedSvg(svgString, shownPrimitives, showStrokeNumbers) }} />)
}
