import { supabase } from "../api"
import { DictionaryEntry, dictionaryEntryQuery } from "../types/DictionaryEntry"
import { ReviewRecord } from "../types/study/ReviewRecord"
import { WordStudy } from "../types/study/WordStudy"
import { WordlistContent } from "../types/wordlist/Wordlist"
import { getOverdueFactor, srsUpdate } from "../utils/srsUtils"
import { generateChallenges } from "./ChallengeGenerator"

async function getWordStudyWithChallenges(reviewRecord: ReviewRecord) {
    const { data: dictionaryEntry, error: postgresError } = await dictionaryEntryQuery().eq('id', reviewRecord.dictionary_entry_id).single()
    if (postgresError)
        return { error: postgresError }

    const { challenges, error: challengeError, warnings } = await generateChallenges(dictionaryEntry)
    if (challengeError)
        return { error: challengeError }

    const wordStudy = {
        dictionaryEntry: dictionaryEntry,
        reviewRecord: reviewRecord,
        challenges: challenges
    } as WordStudy
    return { wordStudy, warnings }
}

function getMostDueReview(reviewRecords: ReviewRecord[]): ReviewRecord | null {
    if (reviewRecords.length < 1)
        return null
    const reviewSort = (a: ReviewRecord, b: ReviewRecord) =>
        getOverdueFactor(b.due_duration_ms, b.last_reviewed) - getOverdueFactor(a.due_duration_ms, a.last_reviewed)
    const mostDueReview = reviewRecords.sort(reviewSort)[0]

    if (mostDueReview)
        return mostDueReview
    else
        return null
}

async function getNextWordStudy(reviewRecords: ReviewRecord[], wordlistContent: WordlistContent[]) {
    const mostDueReview = getMostDueReview(reviewRecords)
    if (mostDueReview && getOverdueFactor(mostDueReview.due_duration_ms, mostDueReview.last_reviewed) >= 1) {
        const { wordStudy, error, warnings } = await getWordStudyWithChallenges(mostDueReview)
        if (error)
            return { error }

        return { nextWordStudy: wordStudy, warnings }
    }

    const dictionaryEntryIdsReviewed = reviewRecords.map(r => r.dictionary_entry_id)
    const newWord = wordlistContent.find(c => c.dictionary_entry ? !dictionaryEntryIdsReviewed.includes(c.dictionary_entry.id) : false)
    if (!newWord) {
        if (mostDueReview) {
            // TODO: notification that reviews are done, continue if desired (isStudyCaughtUp hook)
            const { wordStudy, error, warnings } = await getWordStudyWithChallenges(mostDueReview)
            if (error)
                return { error }

            return { nextWordStudy: wordStudy, warnings }
        }
        else {
            // wordlist is empty
            // TODO: better notification
            return { error: new Error("This wordlist is empty.") }
        }
    }

    const nextWordStudy = {
        dictionaryEntry: newWord.dictionary_entry,
    } as WordStudy
    return { nextWordStudy }
}

export function getUpdatedReviewRecord(reviewRecord: ReviewRecord, mistakesCount: number): ReviewRecord {
    const recallDegree = Math.max(0, 1 - (mistakesCount / 10))
    const previous = {
        difficulty: reviewRecord.difficulty,
        dueDuration: reviewRecord.due_duration_ms,
        lastReviewed: reviewRecord.last_reviewed,
    }
    const srsData = srsUpdate(recallDegree, previous)
    return {
        ...reviewRecord,
        difficulty: srsData.difficulty,
        due_duration_ms: srsData.dueDuration,
        last_reviewed: srsData.lastReviewed,
    } as ReviewRecord
}

export function getNewReviewRecord(userId: string, dictionaryEntry: DictionaryEntry): ReviewRecord {
    const srsData = srsUpdate(0.3)
    return {
        dictionary_entry_id: dictionaryEntry.id,
        difficulty: srsData.difficulty,
        due_duration_ms: srsData.dueDuration,
        last_reviewed: srsData.lastReviewed,
        user_id: userId
    } as ReviewRecord
}

export async function initializeStudy(userId: string, wordlistContent: WordlistContent[], setReviewRecords: (value: ReviewRecord[]) => void) {
    const dictionaryEntryIds = wordlistContent.map(c => c.dictionary_entry?.id)
    const { data: reviewRecords, error } = await supabase.from("review_record").select().eq("user_id", userId).in("dictionary_entry_id", dictionaryEntryIds)
    if (error)
        return { error }

    setReviewRecords(reviewRecords)
    return {}
}

export async function advanceStudy(wordlistContent: WordlistContent[], reviewRecords: ReviewRecord[], setWordStudy: (value: WordStudy) => void) {
    const { nextWordStudy, error, warnings } = await getNextWordStudy(reviewRecords, wordlistContent)
    if (error)
        return { error }

    setWordStudy(nextWordStudy)
    return { warnings }
}
