import { useContext } from 'react'
import { DictionaryEntry } from '../../types/DictionaryEntry'
import { Box, Button, Typography } from '@mui/material'
import { StudyContext } from '../../contexts/StudyContext'

export default function CompletedChallenge({ dictionaryEntry }: { dictionaryEntry: DictionaryEntry }) {
    const studyContext = useContext(StudyContext)
    const headerText = dictionaryEntry?.dictionary_word[0]?.word ?? dictionaryEntry?.dictionary_reading[0]?.reading ?? "[Word missing]"
    const showReading = dictionaryEntry?.dictionary_word[0]?.word && dictionaryEntry?.dictionary_reading[0]?.reading

    return (
        <Box display="flex" flexDirection="column" rowGap="40px">
            <Typography variant="h3" textAlign="center">{headerText}</Typography>
            {showReading &&
                <Typography textAlign="center">{dictionaryEntry.dictionary_reading[0].reading}</Typography>
            }
            <Typography textAlign="center">
                {dictionaryEntry.dictionary_meaning[0]?.dictionary_meaning_translation[0]?.translation ?? "[Translation missing]"}
            </Typography>
            <Box display="flex" justifyContent="center">
                <Button variant="contained" onClick={studyContext.continueAction}>Continue</Button>
            </Box>
        </Box>
    )
}
