import { useCallback, FormEvent, useState, useContext } from "react"
import { supabase } from "../../api"
import useError from "../useError"
import { SnackbarContext } from "../../contexts/SnackbarContext"

export default function useChangePassword(close: () => void) {
    const { handleError } = useError()
    const { snackbarSuccess } = useContext(SnackbarContext)
    const [newPassword, setNewPassword] = useState<string>()
    const [newPasswordMatch, setNewPasswordMatch] = useState<string>()

    const handleSubmitChangePassword = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (!newPassword || newPassword.length < 1 || !newPasswordMatch || newPasswordMatch.length < 1)
            return

        if (newPassword !== newPasswordMatch) {
            handleError(new Error("The entered passwords do not match."))
            return
        }

        const { error } = await supabase.auth.updateUser({
            password: newPassword
        })

        if (error) {
            handleError(error)
            return
        }

        snackbarSuccess("Password updated!")
        close()

    }, [close, handleError, newPassword, newPasswordMatch, snackbarSuccess])

    return { setNewPassword, setNewPasswordMatch, handleSubmitChangePassword }
}
