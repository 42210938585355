import { Box, Button, Modal, TextField, Typography } from "@mui/material"
import { User } from "@supabase/supabase-js"
import { FormEvent, useCallback, useContext, useState } from "react"
import useError from "../../hooks/useError"
import { supabase } from "../../api"
import { SnackbarContext } from "../../contexts/SnackbarContext"

export default function AccountDeleteModal({ open, onClose, user }: { open: boolean, onClose: () => void, user: User }) {
    const [emailMatch, setEmailMatch] = useState<string>()
    const { handleError } = useError()
    const { snackbarSuccess } = useContext(SnackbarContext)
    const tryDeleteAccount = useCallback(async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const { error } = await supabase.functions.invoke('resend', {
            body: { textBody: "Account deletion request", returnEmail: user.email, isIssue: false },
        })
        if (error) {
            handleError(error)
            return
        }

        snackbarSuccess("Account deletion request sent.")
        onClose()
    }, [handleError, onClose, snackbarSuccess, user.email])

    return (
        <Modal open={open} onClose={onClose}>
            <Box sx={styles.modalBox} component="form" display="flex" flexDirection="column" rowGap="20px" onSubmit={tryDeleteAccount}>
                <Typography variant="h6">Enter your email to confirm:</Typography>
                <TextField
                    id="outlined-basic"
                    label="Email"
                    variant="outlined"
                    required
                    onChange={e => setEmailMatch(e.target.value)}
                    inputProps={{ maxLength: 128 }}
                    sx={{ backgroundColor: "grey.50", outlineColor: "error.main" }}
                />
                <Button variant="contained" type="submit" disabled={emailMatch !== user.email}>Continue</Button>
            </Box>
        </Modal>
    )
}

const styles = {
    modalBox: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        padding: 4,
    }
}
