import { Positions } from "../types/study/PrimitiveChallengeTypes";

export function CheckPositionMatch(primitivePosition: Positions, placedPosition: Positions) {
    if (primitivePosition === placedPosition)
        return true

    switch (primitivePosition) {
        case Positions.TARE:
            return placedPosition === Positions.TOP || placedPosition === Positions.LEFT
        case Positions.TAREC:
            return placedPosition === Positions.BOTTOM || placedPosition === Positions.RIGHT
        case Positions.NYO:
            return placedPosition === Positions.BOTTOM || placedPosition === Positions.LEFT
        case Positions.NYOC:
            return placedPosition === Positions.TOP || placedPosition === Positions.RIGHT
        case Positions.KAMAE:
            return placedPosition === Positions.TOP || placedPosition === Positions.RIGHT
        case Positions.KAMAEC:
            return placedPosition === Positions.BOTTOM || placedPosition === Positions.LEFT
    }

    return false
}
