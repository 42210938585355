import { Box, ListItem, Typography } from "@mui/material";
import { DictionaryEntry } from "../../types/DictionaryEntry";

export default function WordSearchboxSuggestion({ dictionaryEntry, props }: { dictionaryEntry: DictionaryEntry, props: React.HTMLAttributes<HTMLLIElement> }) {
    const japaneseText = dictionaryEntry?.dictionary_word[0]?.word ? dictionaryEntry?.dictionary_word[0]?.word : dictionaryEntry.dictionary_reading[0]?.reading
    return (
        <ListItem {...props}>
            <Box>
                <Typography>
                    {japaneseText}
                </Typography>
                <Typography>
                    {dictionaryEntry.dictionary_meaning[0]?.dictionary_meaning_translation[0]?.translation}
                </Typography>
            </Box>
        </ListItem>
    )
}
