import { useCallback } from "react";
import { Wordlist, WordlistLevel } from "../../types/wordlist/Wordlist";

export default function useWordlistLevel(
    currentWordlistLevelId: number | undefined,
    setWordlist: (value: (value: Wordlist | undefined) => Wordlist | undefined) => void
) {
    const getCurrentWordlistLevel = useCallback((wordlist: Wordlist | undefined) => {
        return wordlist?.wordlist_level.find(l => l.id === currentWordlistLevelId)
    }, [currentWordlistLevelId])

    const updateWordlistLevel = useCallback((wordlistLevel: WordlistLevel) => {
        setWordlist(previous => (previous ? {
            ...previous,
            wordlist_level: previous.wordlist_level.map(level =>
                level.id === wordlistLevel.id ? wordlistLevel : level
            )
        } : undefined))
    }, [setWordlist])

    return { getCurrentWordlistLevel, updateWordlistLevel }
}
