import { Button, Container, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material'
import { Fragment, useContext, useState } from 'react'
import Subheader from '../components/Subheader'
import { SessionContext } from '../contexts/SessionContext'
import EmailUpdateModal from '../components/useraccount/EmailUpdateModal'
import PasswordChangeModal from '../components/useraccount/PasswordChangeModal'
import ConfirmationModal from '../components/ConfirmationModal'
import AccountDeleteModal from '../components/useraccount/AccountDeleteModal'

export default function UserAccount() {
    const { session } = useContext(SessionContext)
    const [emailUpdateModalOpen, setEmailUpdateModalOpen] = useState<boolean>(false)
    const [passwordChangeModalOpen, setPasswordChangeModalOpen] = useState<boolean>(false)
    const [deleteAccountConfirmationModalOpen, setDeleteAccountConfirmationModalOpen] = useState<boolean>(false)
    const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState<boolean>(false)

    return (
        <Fragment>
            <Subheader title="User Account" />
            <Container maxWidth="md">
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight="bold">Email:</Typography>
                                </TableCell>
                                <TableCell>{session.user.email}</TableCell>
                                <TableCell>
                                    <Button onClick={() => setEmailUpdateModalOpen(true)}>Update Email</Button>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    <Typography fontWeight="bold">Password:</Typography>
                                </TableCell>
                                <TableCell>**...*</TableCell>
                                <TableCell>
                                    <Button onClick={() => setPasswordChangeModalOpen(true)}>Change Password</Button>
                                </TableCell>
                            </TableRow>
                            <TableRow sx={{ backgroundColor: "error.light" }}>
                                <TableCell colSpan={2}>
                                    <Typography fontWeight="bold">DELETE ACCOUNT:</Typography>
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="outlined"
                                        sx={{
                                            backgroundColor: "error.dark",
                                            color: "error.contrastText",
                                            borderColor: "error.contrastText",
                                            "&:hover":{
                                                borderColor: "error.contrastText"
                                            }
                                        }}
                                        onClick={() => setDeleteAccountConfirmationModalOpen(true)}
                                    >DELETE ACCOUNT</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
            <EmailUpdateModal open={emailUpdateModalOpen} onClose={() => setEmailUpdateModalOpen(false)} />
            <PasswordChangeModal open={passwordChangeModalOpen} onClose={() => setPasswordChangeModalOpen(false)} />
            <ConfirmationModal open={deleteAccountConfirmationModalOpen} onClose={() => setDeleteAccountConfirmationModalOpen(false)}
                title="Really delete your account?"
                text="This action will IRREVERSIBLY delete all of your recorded progress and content."
                confirmAction={() => {
                    setDeleteAccountModalOpen(true)
                    setDeleteAccountConfirmationModalOpen(false)
                }}
            />
            <AccountDeleteModal open={deleteAccountModalOpen} onClose={() => setDeleteAccountModalOpen(false)} user={session.user} />
        </Fragment >
    )
}
