import { Box, CircularProgress } from "@mui/material";
import { PostgrestError } from "@supabase/supabase-js";
import ErrorDisplay from "./ErrorDisplay";
import { Fragment, PropsWithChildren } from "react";

type LoadableProps = {
    isLoading: boolean,
    error?: Error | PostgrestError
}

export default function Loadable(props: PropsWithChildren<LoadableProps>) {
    const { isLoading, error, children } = props
    if (isLoading)
        return <Box display="flex" justifyContent="center"><CircularProgress /></Box>

    if (error || !children)
        return <ErrorDisplay error={error} />

    return <Fragment>{children}</Fragment>
}
