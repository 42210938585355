import { Session } from "@supabase/supabase-js"
import { JwtPayload, jwtDecode } from "jwt-decode"
import { supabase } from "../api"
import { Database } from "../types/supabase"

export async function getRolePermissionLimit(session: Session, permission: Database["public"]["Enums"]["app_permission"]) {
    type jwtCustom = JwtPayload & { user_role: string }

    const jwt = jwtDecode(session.access_token) as jwtCustom

    const { data: rolePermission, error } = await supabase.from('role_permissions').select().eq("permission", permission).eq("role", jwt.user_role).single()
    if (error)
        return { error }
    return { limit: rolePermission.limit }
}
