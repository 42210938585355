import { useCallback, useContext, useEffect, useState } from "react";
import { PostgrestError } from "@supabase/supabase-js";
import { WordlistSnapshot, wordlistSnapshotQuery } from "../../types/wordlist/WordlistSnapshot";
import { SessionContext } from "../../contexts/SessionContext";
import useError from "../useError";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../api";

async function getWordlistSnapshots(publicOnly: boolean, userId?: string, limit?: number) {
    let query = supabase.from('wordlist').select(wordlistSnapshotQuery)
    if (publicOnly)
        query = query.eq('is_public', true)
    if (userId)
        query = query.eq('user_id', userId)
    query = query.order('date_created')
    if (limit)
        query = query.limit(limit)
    return await query
}

function getTotalWordCount(wordlistSnapshot: WordlistSnapshot) {
    return wordlistSnapshot.wordlist_level.map(c => c.wordlist_content.find(c => c)?.count ?? 0).reduce((acc, cur) => acc + cur, 0)
}

export default function useWordlistSnapshots(publicOnly: boolean, ownerOnly: boolean, limit?: number) {
    const { session } = useContext(SessionContext)
    const { handleError } = useError()
    const [wordlistSnapshots, setWordlistSnapshots] = useState<WordlistSnapshot[]>()
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState<PostgrestError>()
    const navigate = useNavigate()

    useEffect(() => {
        async function doAsync() {
            if (!wordlistSnapshots) {
                const { data, error } = await getWordlistSnapshots(publicOnly, ownerOnly ? session.user.id : undefined, limit)
                if (error) {
                    setError(error)
                    handleError(error)
                    return
                }

                setWordlistSnapshots(data)
                setIsLoading(false)
            }
        }

        doAsync()
    }, [handleError, limit, ownerOnly, publicOnly, session.user.id, wordlistSnapshots])

    const viewWordlist = useCallback((wordlistId: string) => {
        navigate("/wordlist/" + wordlistId)
    }, [navigate])

    return { wordlistSnapshots, setWordlistSnapshots, isLoading, error, getTotalWordCount, viewWordlist }
}
